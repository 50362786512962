import { ConnectKitButton } from "connectkit";

export const ChainDropdown = () => {
	return (
		<ConnectKitButton.Custom>
			{({ isConnected, show, truncatedAddress, ensName }) => {
				return (
					<div>
						hi
					</div>
				);
			}}
		</ConnectKitButton.Custom>
	);
};

export const BSButton = () => {
	return (
		<ConnectKitButton.Custom>
			{({ isConnected, show, truncatedAddress, ensName }) => {
				return (
					<div>
						<ConnectKitButton label={isConnected ? ensName ?? truncatedAddress : "Connect Wallet"} />
					</div>
				);
			}}
		</ConnectKitButton.Custom>
	);
};

/*
						<ConnectKitButton label={isConnected ? ensName ?? truncatedAddress : "Connect Wallet"} />
						<ConnectKitButton label={isConnected ? ensName ?? truncatedAddress : "Hi. I❤️U. I'm not a \uD83D\uDC08."} />

 */