import React, {useState} from 'react';
import './App.css';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

import Spreadsheet from "react-spreadsheet";

import { WagmiConfig, createClient, chain } from "wagmi";
import { ConnectKitProvider, ConnectKitButton, getDefaultClient, useModal } from "connectkit";
import {BSButton, ChainDropdown} from "./BSButton";
import ChainSelectDropdown from "connectkit/build/components/Common/ChainSelectDropdown";
import {log} from "util";
import {Connector} from "@wagmi/core/dist/declarations/src/connectors";
import {getChainNameFromID} from "./Util";
import {NativeSelect, Select, SelectChangeEvent} from "@mui/material";

//import { useNetwork } from 'wagmi'
//import { useSwitchNetwork } from 'wagmi'

const alchemyId = process.env.ALCHEMY_ID;

const chains = [chain.mainnet, chain.optimism, chain.arbitrum, chain.polygon, chain.goerli, chain.localhost];

const client = createClient(
	getDefaultClient({appName: "bs3b", alchemyId, chains}),
);


const darkTheme = createTheme({
	                              palette: {
		                              mode: 'dark',
	                              },
                              });

function App()
{
//	const [firstTime, setFirstTime] = useState(true);
//	const { switchNetwork } = wagmi.useSwitchNetwork()

	const [auth] = useState(true);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const [data, setData] = useState([
		                                 [{ value: "Vanilla" }, { value: "Chocolate" }],
		                                 [{ value: "Strawberry" }, { value: "Cookies" }],
	                                 ]);

	const [chainID, setChainID] = useState<number | undefined>(client.lastUsedChainId);
	const numericChainID: number = chainID == undefined ? -1 : chainID as number;

	const [connector, setConnector] = useState<Connector<any, any, any> | undefined>(client.connector);

	const chainName = getChainNameFromID(chainID);

//	const open = useModal();
//	const setOpen = useModal();

//	const { chain } = useNetwork();

//	const { switchNetwork } = useSwitchNetwork()


	client.subscribe((selectedState, previousSelectedState) =>
	                 {
				log("Switch from " + previousSelectedState);
				log("To new state: " + selectedState);

		                 setChainID(client.lastUsedChainId);
				 setConnector(client.connector);
		                 log("New chain id: " + chainID);
		                 const xxx = connector?.getAccount();
//		                 xxx.

		                 log("Chain type: " + (typeof chain));
//		                 chain = chain.polygon;

	                 });

//	const cProps :ConnectKitButtonProps = ConnectKitButton();


/*	var switchNetwork = undefined;

	if(firstTime)
	{
		setFirstTime(false);
	}
	else
	{
		const { switchNetwork } = useSwitchNetwork();
	}
*/

//	const { chains, error, isLoading, pendingChainId, switchNetwork } = useSwitchNetwork();

//	function handleSelectChain(event : SelectChangeEvent<number>)
	function handleSelectChain(event: React.ChangeEvent<HTMLSelectElement>)
	{
		log("Select chain: " + event.target.value);
		log("type: " + typeof event.target.value);

		const newChainID : number = parseInt(event.target.value);

//		const newChainName = getChainNameFromID(event.target.value as number);
		const newChainName = getChainNameFromID(newChainID);
		log("New Chain Name: " + newChainName);
//		client.setLastUsedConnector("" + (event.target.value));

//		log("" + client.con)
/*                if(switchNetwork != undefined)
                {
	                switchNetwork(newChainID);
                }
*/
		if((connector != undefined) && (connector.switchChain != undefined))
		{
			connector.switchChain(newChainID);
		}
	}

	return (
		<WagmiConfig client={client}>
		<ConnectKitProvider options={{walletConnectName:"WalletConnect/Other"}}
			>
		<ThemeProvider theme={darkTheme}>
		<CssBaseline />
		<Box sx={{ flexGrow: 1 }}>
			<AppBar position="static">
				<Toolbar>
					<IconButton
						size="large"
						edge="start"
						color="inherit"
						aria-label="menu"
						sx={{ mr: 2 }}
					>
						<MenuIcon />
					</IconButton>
					<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
						BS -- bs3:v0.01
					</Typography>

					{numericChainID == -1 ? <div/> :
					 <NativeSelect
						 value={numericChainID}
						 onChange={handleSelectChain}
					 >
						 <option value={1}>Ethereum</option>
						 <option value={10}>Optimism</option>
						 <option value={42161}>Arbitrum</option>
						 <option value={137}>Polygon</option>
						 <option value={5}>Goerli</option>
						 <option value={1337}>Localhost</option>
					 </NativeSelect>
					}
					<BSButton />

				</Toolbar>
			</AppBar>

			{chainID == undefined ? "no chain ID" : ("chainID: " + chainName)}
			<ConnectKitButton.Custom>
				{({ isConnected, show, truncatedAddress, ensName }) => {
					return (
						<div>
							ChainID: {client.lastUsedChainId}
							<p/>
							connected: {isConnected}
							<p/>
							truncatedAddress: {truncatedAddress}
							<p/>
							ensName: {ensName}
						</div>
					);
				}}
			</ConnectKitButton.Custom>

			<Spreadsheet data={data} />

		</Box>

		</ThemeProvider>
		</ConnectKitProvider>
		</WagmiConfig>
	);
}

export default App;

/*
					<Select
						value={numericChainID}
						label='Network'
						onChange={handleSelectChain}
					>
						<MenuItem value={1}>Ethereum Mainnet</MenuItem>
						<MenuItem value={10}>Optimism</MenuItem>
						<MenuItem value={42161}>Arbitrum</MenuItem>
						<MenuItem value={137}>Polygon</MenuItem>
						<MenuItem value={5}>Goerli</MenuItem>
						<MenuItem value={1337}>Localhost</MenuItem>
					</Select>

					{/*chainID == undefined ? "" : ("Connected: " + chainName)*}

					<ChainDropdown />
					<ConnectKitButton />
					<ExampleButton />
			return <Spreadsheet data={data} onChange={setData} />;
 */