
import { chainId } from "wagmi";

export function getChainNameFromID(id : number | undefined)
{
	switch(id)
	{
	case chainId.mainnet:
		return "Ethereum";

	case chainId.ropsten:
		return "Ropsten";

	case chainId.rinkeby:
		return "Rinkeby";

	case chainId.goerli:
		return "Goerli";

	case chainId.kovan:
		return "Kovan";

	case chainId.sepolia:
		return "Sepolia";

	case chainId.optimism:
		return "Optimism";

	case chainId.optimismKovan:
		return "Optimism Kovan";

	case chainId.optimismGoerli:
		return "Optimism Goerli";

	case chainId.polygon:
		return "Polygon";

	case chainId.polygonMumbai:
		return "Polygon Mumbai";

	case chainId.arbitrum:
		return "Arbitrum";

	case chainId.arbitrumRinkeby:
		return "Arbitrum Rinkeby";

	case chainId.arbitrumGoerli:
		return "Arbitrum Goerli";

	case chainId.localhost:
		return "Localhost";

	case chainId.hardhat:
		return "Hardhat";

	case 56:
		return "BNB Smart Chain";

	case undefined:
	default:
		return "Unknown Network";
	}
}